<template>
  <div id="container" v-if="professionnel">
    <img
      class="photoProfessionnel"
      :src="getStorageUrl() + professionnel.photo"
      v-if="!siDefautImage"
      @error="mettreDefautPhoto"
    />
    <img
      class="photoProfessionnel"
      src="@/assets/blankProfilePicture.png"
      v-if="siDefautImage"
    />
    <div id="text">
      <div class="smaller">
        <h1 class="titre">{{ fullName }}</h1>
        <p class="subTitle">{{ professionnel.categoryName }}</p>
        <button
          class="rdv btnRendezVousFiche"
          v-if="!siProfessionnel"
          type="button"
          name="button"
          @click="ouvrirPriseRv"
        >
          Prendre rendez-vous
        </button>
        <div class="section aPropos" style="min-height: 17vh">
          <div class="blueBorder">
            <h3 class="sectionTitle">À propos</h3>
          </div>
          <p class="smallText">
            {{ professionnel.description }}
          </p>
        </div>

        <div
          class="section Specialites"
          style="min-height: 17vh"
          v-if="professionnel.specialites.length > 0"
        >
          <div class="blueBorder">
            <h3 class="sectionTitle">Spécialités</h3>
          </div>
          <div class="hover_" style="margin-top: 15px">
            <p
              style="
                border: 1px solid #235c8f;
                border-radius: 25px;
                padding: 5px;
              "
              v-for="specialite in professionnel.specialites"
              class="smallText"
              :key="specialite.specialite_id"
            >
              {{ specialite.nom }}
            </p>
          </div>
        </div>

        <div
          class="section diplomes"
          style="min-height: 17vh"
          v-if="professionnel.diplomes.length > 0"
        >
          <div class="blueBorder">
            <h3 class="sectionTitle">Diplômes</h3>
          </div>
          <div>
            <div
              v-for="diplome in professionnel.diplomes"
              class="smallText"
              :key="diplome.diplome_id"
            >
              <h6>{{ diplome["annee_diplome"] }}</h6>
              <p style="margin-top: -9px">{{ diplome["titre_diplome"] }}</p>
            </div>
          </div>
        </div>
        <div
          class="section Langues"
          style="min-height: 17vh"
          v-if="professionnel.langues.length > 0"
        >
          <div class="blueBorder">
            <h3 class="sectionTitle">Langues</h3>
          </div>
          <div>
            <p>
              <label
                v-for="langue in professionnel.langues"
                class="smallText"
                :key="langue.langue_id"
              >
                <span v-if="langue['compteur'] == 0">{{
                  langue["langue"]
                }}</span>
                <span v-if="langue['compteur'] > 0"
                  >, {{ langue["langue"] }}</span
                >
              </label>
            </p>
          </div>
        </div>
        <div
          class="section permis"
          style="min-height: 17vh"
          v-if="professionnel.permis.length > 0"
        >
          <div class="blueBorder">
            <h3 class="sectionTitle">Permis de pratique</h3>
          </div>
          <div>
            <div
              v-for="permis in professionnel.permis"
              class="smallText"
              :key="permis.titre_permis"
            >
              <p>
                <a
                  class="link_"
                  target="_blank"
                  :href="getStorageUrl() + permis['lien']"
                  ><img
                    width="20"
                    height="20"
                    src="@/assets/download.png"
                    alt=""
                  />{{ permis["titre_permis"] }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-dispo"
      size="xl"
      centered
      hide-footer
      title="Disponibilités"
    >
      <RendezVousPopup v-bind:professionnel="professionnel" />
    </b-modal>
  </div>
</template>

<script>
import RendezVousPopup from "@/components/RendezVousPopup";
export default {
  name: "PageInfoProfessionnel",
  components: {
    RendezVousPopup,
  },
  data() {
    return {
      siDefautImage: false,
    };
  },
  computed: {
    professionnel() {
      return this.$store.getters.professionnel(this.$route.params.id);
    },
    siProfessionnel() {
      return sessionStorage.getItem("role") === "2";
    },
    photo() {
      return process.env.VUE_APP_BACKEND_URL + this.professionnel.photo;
    },
    fullName() {
      return this.professionnel.first_name + " " + this.professionnel.last_name;
    },
  },
  methods: {
    ouvrirPriseRv() {
      this.$bvModal.show("modal-dispo");
    },
    mettreDefautPhoto() {
      this.siDefautImage = true;
    },
    getStorageUrl() {
      return process.env.VUE_APP_BACKEND_STORAGE_URL;
    },
  },
  created() {
    this.$store.dispatch("fetchProfessionnels");
  },
};
</script>

<style scoped>
#container {
  background-color: white;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.photoProfessionnel {
  width: 300px;
  height: 300px;
  object-fit: contain;
  vertical-align: top;
}

.titre {
  font-family: roboto;
  font-size: 36px;
  font-weight: bold;
  text-align: left !important;
  margin-bottom: 0px;
}

.subTitle {
  font-family: roboto;
  font-size: 18px;
  color: #235c8f;
  font-weight: bold;
  margin-bottom: 8px;
}

.rdv {
  color: white;
  border-radius: 5px;
  border: 0px;
  outline: none;
  font-size: 15px;
  padding: 5px;
  font-family: roboto;
  font-weight: bold;
  margin-bottom: 18px;
}
.rdv:hover {
  background-color: #235c8f !important;
}
#text {
  text-align: left;
  width: 60%;
}

.section {
  margin-bottom: 18px;
}

.blueBorder {
  border-top: 3px solid #235c8f;
  background-color: #e9f0f5;
}

.sectionTitle {
  font-size: 18px;
  font-family: roboto;
  font-weight: bold;
  color: #235c8f;
  margin: 5px;
}

.smallText {
  font-family: roboto;
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .photoProfessionnel {
    width: 200px;
    height: 200px;
  }

  #text {
    width: 45%;
  }

  .photoProfessionnel {
    margin-right: 18px;
  }
}

@media only screen and (min-width: 768px) {
  #text {
    width: 40%;
  }
}

@media only screen and (min-width: 992px) {
  .photoProfessionnel {
    width: 225px;
    height: 225px;
    object-fit: contain;
    vertical-align: top;
  }
}

@media only screen and (min-width: 1200px) {
  .photoProfessionnel {
    width: 250px;
    height: 250px;
    object-fit: contain;
    vertical-align: top;
  }
}

@media screen and (max-width: 1024px) {
  #container {
    width: 90%;
  }
  .photoProfessionnel {
    width: 100%;
  }
  #text {
    margin-top: 30px;
    width: 90%;
  }
}

.hover_ p:hover {
  background: #e9f0f5;
}

.link_ {
  color: inherit;
  text-decoration: none;
}
</style>
