import axios from "axios";

const state = {
  usersClient: [],
  usersEntreprise: [],
};

const getters = {
  usersClient: (state) => state.usersClient,
  usersEntreprise: (state) => state.usersEntreprise,
};

const mutations = {
  setUsersClient(state, usersClient) {
    state.usersClient = usersClient;
  },
  setUsersEntreprise(state, users) {
    state.usersEntreprise = users;
  },
};

const actions = {
  register(context, informations) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/register",
        method: "post",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: informations,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUsersClient({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/users/role/3",
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setUsersClient", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUsersByEntreprise({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/users/entreprise/" + id,
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setUsersEntreprise", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.error(
            "Erreur lors de la récupération des utilisateurs:",
            err.response ? err.response.data : err.message
          );
          reject(err);
        });
    });
  },
  usersImport(context, { file, entrepriseId }) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entrepriseId", entrepriseId);

    return axios({
      url: "/users-import",
      method: "post",
      baseURL: process.env.VUE_APP_BACKEND_URL,
      data: formData,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message || "Import failed");
        }
      })
      .catch((error) => {
        console.error(
          "Import error:",
          error.response ? error.response.data : error.message
        );
        throw error;
      });
  },
  addSelectedUsersToEntreprise(context, { entrepriseId, users }) {
    return axios({
      url: `/users/entreprise/${entrepriseId}`,
      method: "post",
      baseURL: process.env.VUE_APP_BACKEND_URL,
      data: { users },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(
          "Erreur lors de l'ajout des utilisateurs à l'entreprise:",
          error.response ? error.response.data : error.message
        );
        throw error;
      });
  },
  removeEmployeeFromEntreprise(context, { userId, entrepriseId }) {
    return axios({
      url: `/users/entreprise/${userId}/${entrepriseId}`,
      method: "delete",
      baseURL: process.env.VUE_APP_BACKEND_URL,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la suppression de l'employé de l'entreprise:",
          error.response ? error.response.data : error.message
        );
        throw error;
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
