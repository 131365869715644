<template>
  <div class="container" v-if="isReady">
    <h1>Gestion du profil</h1>
    <b-form v-on:submit.prevent="soumettre" class="form-container">
      <template v-for="field in fields">
        <b-form-group
          :key="field.id"
          :label="field.label"
          :label-for="field.id"
          class="inputGroup"
        >
          <b-form-select
            v-if="field.type === 'select'"
            :id="field.id"
            v-model="item[field.id]"
            :options="forfaits"
            :text-field="field.textField"
            :value-field="field.valueField"
          />

          <b-form-input
            v-else
            :required="field.required"
            :type="field.type"
            :id="field.id"
            :placeholder="field.label"
            v-model="item[field.id]"
          />
        </b-form-group>
      </template>

      <div class="btnContainer">
        <b-button class="buttonSoumettre" type="submit">Soumettre</b-button>
        <b-button class="buttonBack" @click="back()">Annuler</b-button>
      </div>

      <span
        style="color: red"
        v-for="erreur in listeErreurs"
        v-bind:key="erreur"
        >{{ erreur }}
        <br />
      </span>
    </b-form>

    <b-modal
      id="modal-chargement"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Chargement"
    >
      Chargement...
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "entrepriseSettings",
  /**
   * Initializes the component's data.
   *
   * @return {Object} The component's initial data.
   */
  data() {
    return {
      item: {},
      listeErreurs: [],
      isForfaitsLoaded: false,
      retourServeur: "",
      isReady: false,
      forfaits: [],
    };
  },
  computed: {
    /**
     * Checks if the current route parameter 'id' is null, indicating a new item.
     *
     * @return {boolean} True if the 'id' parameter is null, false otherwise.
     */
    siNouveau() {
      return this.$route.params.id == null;
    },
    /**
     * Returns an array of field objects, each containing id, label, type, and required properties.
     *
     * @return {Array} An array of field objects.
     */
    fields() {
      const fields = [
        {
          id: "name",
          label: "Nom de la compagnie",
          type: "text",
          required: true,
        },
        {
          id: "first_name",
          label: "Prénom de la personne ressource",
          type: "text",
          required: true,
        },
        {
          id: "last_name",
          label: "Nom de famille de la personne ressource",
          type: "text",
          required: true,
        },
        {
          id: "role",
          label: "Rôle de la personne ressource",
          type: "text",
          required: true,
        },
        {
          id: "phone",
          label: "Téléphone de la personne ressource",
          type: "text",
          required: true,
        },
        {
          id: "poste",
          label: "Numéro de poste de la personne ressource",
          type: "text",
          required: false,
        },
        {
          id: "email",
          label: "Courriel de la personne ressource",
          type: "email",
          required: true,
        },
        {
          id: "forfait_id",
          label: "Forfait",
          type: "select",
          valueField: "id",
          textField: "name",
          options: this.getForfaits,
        },
      ];

      return fields;
    },
  },
  methods: {
    getForfaits() {
      return this.forfaits;
    },
    soumettre() {
      this.listeErreurs = [];
      if (!this.item.name) {
        this.listeErreurs.push("Le nom de l'entreprise est requis");
      }

      if (!this.item.first_name) {
        this.listeErreurs.push("Le prénom de la personne contact est requis");
      }

      if (!this.item.last_name) {
        this.listeErreurs.push(
          "Le nom de famille de la personne contact est requis"
        );
      }

      if (!this.item.role) {
        this.listeErreurs.push("Le rôle de la personne contact est requis");
      }

      if (!this.item.phone) {
        this.listeErreurs.push("Le numéro de la personne contact est requis");
      }

      if (!this.item.email) {
        this.listeErreurs.push("Le courriel de la personne contact est requis");
      }

      if (this.listeErreurs.length === 0) {
        this.$bvModal.show("modal-chargement");
        this.$store
          .dispatch("entreprises/registerOrUpdateEntreprise", this.item)
          .then((response) => {
            if (response.status === 200) {
              console.log("Response", response);
              if (this.$route.params.id) {
                this.$toast.success("L'entreprise a été modifié avec succès", {
                  duration: 5000,
                });
              } else {
                history.pushState({}, document.title, window.location.href);
                this.$toast.success("L'entreprise a été ajoutée avec succès", {
                  duration: 5000,
                });
              }
              this.$router.push({ name: "ListeEntreprises" });
              this.$bvModal.hide("modal-chargement");
            }
          })
          .catch((err) => {
            const errors = err.response.data;
            console.error("Erreur", errors);
            this.$bvModal.hide("modal-chargement");
          });
      }
    },
    back() {
      this.$router.push({ path: "/admin/liste-entreprises" });
    },
  },
  created() {
    this.$store.dispatch("forfaits/getAllForfaits").then((forfaits) => {
      forfaits.push({
        id: null,
        name: "Aucun",
      });
      this.forfaits = forfaits;
      this.isForfaitsLoaded = true;
    });
    if (!this.siNouveau) {
      this.$store
        .dispatch("entreprises/getEntreprise", this.$route.params.id)
        .then((item) => {
          this.item = item;
          this.isReady = true;
        });
    } else {
      this.item = {};
      this.isReady = true;
    }
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.inputGroup {
  width: 100%;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>
