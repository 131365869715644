<template>
  <AdminPageWrapper>
    <div class="container" v-if="isReady">
      <b-form v-on:submit.prevent="soumettre" class="form-container">
        <template v-for="field in fields">
          <b-form-group
            :key="field.id"
            :label="field.label"
            :label-for="field.id"
            class="inputGroup"
          >
            <b-form-select
              v-if="field.type === 'select'"
              :id="field.id"
              v-model="item[field.id]"
            >
              <b-form-select-option
                v-for="option in field.options()"
                v-bind:key="option.id"
                v-bind:value="option.id"
              >
                {{ option.name }}
              </b-form-select-option>
            </b-form-select>
            <b-form-file
              v-else-if="field.type === 'photo'"
              :required="field.required"
              :id="field.id"
              :placeholder="field.label"
              v-model="logoFile"
            />
            <b-form-textarea
              v-else-if="field.type === 'textarea'"
              type="text"
              :id="field.id"
              :placeholder="field.label"
              v-model="item[field.id]"
            />
            <b-form-input
              v-else
              :required="field.required"
              :type="field.type"
              :id="field.id"
              :placeholder="field.label"
              v-model="item[field.id]"
            />
          </b-form-group>
        </template>
        <div class="btnContainer">
          <b-button class="buttonSoumettre" type="submit">Soumettre</b-button>
          <b-button class="buttonBack" @click="back()">Annuler</b-button>
        </div>
        <span
          style="color: red"
          v-for="erreur in listeErreurs"
          v-bind:key="erreur"
          >{{ erreur }}<br
        /></span>
      </b-form>
      <b-modal
        id="modal-chargement"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :hide-header-close="true"
        centered
        hide-footer
        title="Chargement"
      >
        Chargement...
      </b-modal>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "../components/AdminPageWrapper.vue";
export default {
  name: "PageGestionOrdre",
  components: {
    AdminPageWrapper,
  },
  /**
   * Initializes the component's data properties.
   *
   * @return {Object} An object containing the component's data properties.
   */
  data() {
    return {
      item: {},
      logoFile: null,
      listeErreurs: [],
      retourServeur: "",
      isReady: false,
    };
  },
  computed: {
    /**
     * Checks if the current route parameter 'id' is null, indicating a new item.
     *
     * @return {boolean} True if the 'id' parameter is null, false otherwise.
     */
    siNouveau() {
      return this.$route.params.id == null;
    },
    fields() {
      const fields = [
        {
          id: "nom",
          label: "Nom",
          type: "text",
          required: true,
        },
        {
          id: "logo",
          label: "Logo",
          type: "photo",
          required: this.siNouveau,
        },
      ];
      return fields;
    },
  },
  methods: {
    /**
     * Submits the current order, validating and sending the data to the server.
     *
     * @return {void}
     */
    soumettre() {
      this.listeErreurs = [];
      if (!this.item.nom) {
        this.listeErreurs.push("Le nom est requis");
      }
      if (this.listeErreurs.length === 0) {
        this.$bvModal.show("modal-chargement");
        this.retourServeur = this.$store
          .dispatch("registerOrUpdateOrdre", {
            ordre: this.item,
            logo: this.logoFile,
          })
          .then((response) => {
            if (response.status === 200) {
              if (this.$route.params.id) {
                this.$toast.success("L'ordre à été modifié avec succès", {
                  duration: 5000,
                });
              } else {
                history.pushState({}, document.title, window.location.href);
                this.$toast.success("L'ordre à été créé avec succès", {
                  duration: 5000,
                });
              }
              this.$router.push({ name: "ListeOrdres" });
              this.$bvModal.hide("modal-chargement");
            }
          })
          .catch((err) => {
            const errors = err.response.data;
            console.error(errors);
            this.$bvModal.hide("modal-chargement");
          });
      }
    },
    /**
     * Navigates back to the orders list page.
     *
     * @return {void}
     */
    back() {
      this.$router.push({ path: "/admin/liste-ordres" });
    },
  },
  /**
   * Lifecycle hook that initializes the component by fetching or setting the order item.
   *
   * If the current route has an 'id' parameter, it dispatches an action to retrieve the order item from the store.
   * Otherwise, it sets the order item to an empty object.
   *
   * @return {void}
   */
  created() {
    if (!this.siNouveau) {
      this.$store.dispatch("getOrdre", this.$route.params.id).then((item) => {
        this.item = item;
        this.isReady = true;
      });
    } else {
      this.item = {};
      this.isReady = true;
    }
  },
};
</script>

<style scoped>
img {
  width: 100px;
  height: 100px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.inputGroup {
  width: 100%;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>
