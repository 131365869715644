import axios from "axios";

const state = {
  consultationsUtilisees: 0,
  consultationsTotal: 0,
  consultationsRestantes: 0,
  message: null,
  error: null,
};

const mutations = {
  setConsultations(state, data) {
    state.consultationsUtilisees = data.consultations_utilisees;
    state.consultationsTotal = data.consultations_total;
    state.consultationsRestantes = data.consultations_restantes;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async incrementerConsultation({ commit }, entrepriseId) {
    try {
      const response = await axios({
        url: `/consultations/${entrepriseId}/incrementer`,
        method: "post",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      });

      commit("setMessage", response.data.message);
      return response.data;
    } catch (error) {
      commit("setError", error.response.data.message);
      throw error;
    }
  },

  async decrementerConsultation({ commit }, entrepriseId) {
    try {
      const response = await axios({
        url: `/consultations/${entrepriseId}/decrementer`,
        method: "post",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      });

      commit("setMessage", response.data.message);
      return response.data;
    } catch (error) {
      commit("setError", error.response.data.message);
      throw error;
    }
  },

  async getConsultationsRestantes({ commit }, entrepriseId) {
    try {
      const response = await axios({
        url: `/consultations/${entrepriseId}/restantes`,
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      });

      commit("setConsultations", response.data);
      return response.data;
    } catch (error) {
      commit("setError", error.response.data.message);
      throw error;
    }
  },

  // Revoir pour la condition. Y aller avec le calendrier à place de paiement

  async traiterPaiementClient({ dispatch }, { entrepriseId }) {
    try {
      await dispatch("incrementerConsultation", entrepriseId);
    } catch (error) {
      throw error;
    }
  },

  async traiterRemboursementClient({ dispatch }, { entrepriseId }) {
    try {
      await dispatch("decrementerConsultation", entrepriseId);
    } catch (error) {
      throw error;
    }
  },
};

const getters = {
  getConsultationsUtilisees: (state) => state.consultationsUtilisees,
  getConsultationsTotal: (state) => state.consultationsTotal,
  getConsultationsRestantes: (state) => state.consultationsRestantes,
  getMessage: (state) => state.message,
  getError: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
