import EntrepriseForfait from "@/components/EntrepriseForfait.vue";
import EntrepriseSettings from "@/components/EntrepriseSettings.vue";
import EntrepriseStats from "@/components/EntrepriseStats.vue";
import EntrepriseUsers from "@/components/EntrepriseUsers.vue";
import MotDePasseOublie from "@/components/MotDePasseOublie.vue";
import NouveauMotDePasse from "@/components/nouveauMotDePasse.vue";
import Home from "@/views/Home.vue";
import ListeCategories from "@/views/ListeCategories.vue";
import ListeEntreprises from "@/views/ListeEntreprises.vue";
import ListeForfaits from "@/views/ListeForfaits.vue";
import ListeOrdres from "@/views/ListeOrdres.vue";
import ListeProfessionnels from "@/views/ListeProfessionnels.vue";
import ListeUtilisateur from "@/views/ListeUtilisateur.vue";
import ModificationProfil from "@/views/ModificationProfil.vue";
import MultiFactorAuth from "@/views/MultiFactorAuth.vue";
import PageAdmin from "@/views/PageAdmin.vue";
import PageGestionCategorie from "@/views/PageGestionCategorie.vue";
import PageGestionCompte from "@/views/PageGestionCompte.vue";
import PageGestionCompteProf from "@/views/PageGestionCompteProf.vue";
import PageGestionEntreprise from "@/views/PageGestionEntreprise.vue";
import PageGestionForfait from "@/views/PageGestionForfait.vue";
import PageGestionOrdre from "@/views/PageGestionOrdre.vue";
import PageInfoProfessionnel from "@/views/PageInfoProfessionnel.vue";
import Payment from "@/views/Payment.vue";
import ProfilUtilisateur from "@/views/ProfilUtilisateur.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/multifactor",
    name: "MultiFactor",
    component: MultiFactorAuth,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: {
      requiresLogin: true,
    },
    props: true,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/calendrier",
    name: "Calendrier",
    component: ProfilUtilisateur,
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/profil",
    name: "Profil",
    component: ModificationProfil,
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/professionnels",
    name: "listeProfessionnels",
    component: ListeProfessionnels,
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/professionnels/:id",
    name: "infoProfessionnel",
    component: PageInfoProfessionnel,
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: "/admin",
    name: "pageAdmin",
    component: PageAdmin,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/gestionCompteProf/:id?",
    name: "pageGestionCompteProf",
    component: PageGestionCompteProf,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/gestionCompte/:id?",
    name: "pageGestionCompte",
    component: PageGestionCompte,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/mot-de-passe",
    name: "motDePasseOublie",
    component: MotDePasseOublie,
  },
  {
    path: "/newPassword",
    name: "nouveauMotDePasse",
    component: NouveauMotDePasse,
  },
  {
    path: "/admin/utilisateurs",
    name: "ListeUtilisateur",
    component: ListeUtilisateur,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/liste-ordres",
    name: "ListeOrdres",
    component: ListeOrdres,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/ordre/:id?",
    name: "pageGestionOrdre",
    component: PageGestionOrdre,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/liste-categories",
    name: "ListeCategories",
    component: ListeCategories,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/category/:id?",
    name: "pageGestionCategorie",
    component: PageGestionCategorie,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/liste-entreprises",
    name: "ListeEntreprises",
    component: ListeEntreprises,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/entreprise/:id?",
    name: "pageGestionEntreprise",
    component: PageGestionEntreprise,
    meta: {
      requiresAdmin: true,
    },
    children: [
      {
        path: "stats",
        name: "entrepriseStats",
        component: EntrepriseStats,
      },
      {
        path: "employees",
        name: "entrepriseUsers",
        component: EntrepriseUsers,
      },
      {
        path: "forfait",
        name: "entrepriseForfait",
        component: EntrepriseForfait,
      },
      {
        path: "profil",
        name: "entrepriseSettings",
        component: EntrepriseSettings,
      },
    ],
  },
  {
    path: "/admin/liste-forfaits",
    name: "ListeForfaits",
    component: ListeForfaits,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/forfait/:id?",
    name: "pageGestionForfait",
    component: PageGestionForfait,
    meta: {
      requiresAdmin: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // sourcery skip: avoid-using-var
  var isLoggedIn = sessionStorage.getItem("token");
  var isAdmin = parseInt(sessionStorage.getItem("role")) === 1;
  if (to.matched.some((record) => record.meta.requiresLogin) && !isLoggedIn) {
    next({ name: "Home" });
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (isLoggedIn && isAdmin) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else if (to.matched.some((record) => record.name === "Home")) {
    if (isLoggedIn) {
      if (isAdmin) {
        next({ name: "pageAdmin", query: to.query });
      } else {
        next({ name: "listeProfessionnels" });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
