<template>
  <AdminPageWrapper>
    <div>
      <div class="buttonNouveauContainer">
        <b-button
          class="buttonNouveau"
          type="submit"
          @click="ajouterProfessionnel()"
          >Ajouter Nouveau</b-button
        >
      </div>
      <br />
      <table class="tableauProf">
        <tr>
          <th>ID</th>
          <th>Prénom</th>
          <th>Nom</th>
          <th>Catégorie</th>
          <th>Est enregistré avec Microsoft</th>
          <th>Taxable</th>
          <th>Modifier</th>
          <th>Supprimer</th>
        </tr>
        <tr v-for="prof in listeProfessionnels" v-bind:key="prof.id">
          <td>{{ prof.id }}</td>
          <td>{{ prof.first_name }}</td>
          <td>{{ prof.last_name }}</td>
          <td>{{ prof.categoryName }}</td>
          <td v-if="prof.hasBeenAddedToMicrosoftGraph">Oui</td>
          <td v-else>Non</td>
          <td>
            <b-form-checkbox
              :checked="prof.isTaxable == 1"
              @change="isTaxableChange(prof.id, $event)"
            />
          </td>
          <td>
            <b-button
              class="buttonModifier"
              type="submit"
              @click="modifierProfessionnel(prof.id)"
              >Modifier</b-button
            >
          </td>
          <td>
            <b-button @click="supprimerProfessionnel(prof.id)"
              >Supprimer</b-button
            >
          </td>
        </tr>
      </table>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "../components/AdminPageWrapper.vue";
export default {
  name: "PageInfoProfessionnel",
  components: {
    AdminPageWrapper,
  },
  computed: {
    listeProfessionnels() {
      return this.$store.getters.listeProfessionnels;
    },
  },
  methods: {
    isTaxableChange(userId, taxable) {
      this.$store.dispatch("saveTaxableForUser", { userId: userId, taxable });
    },
    modifierProfessionnel: function (idProfessionnel) {
      history.pushState({}, document.title, window.location.href);
      this.$router.push({
        name: "pageGestionCompteProf",
        params: { id: idProfessionnel },
      });
    },
    ajouterProfessionnel: function () {
      history.pushState({}, document.title, window.location.href);
      this.$router.push({ name: "pageGestionCompteProf" });
    },
    supprimerProfessionnel(id) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer cet utilisateur?", {
          title: "Supprimer",
          cancelTitle: "Non",
          okTitle: "Oui",
        })
        .then((canDelete) => {
          if (canDelete) {
            this.$store
              .dispatch("deleteProfessionnel", id)
              .then(() => {
                this.$toast.success("Utilisateur supprimé avec succès", {
                  duration: 5000,
                });
                this.$store.dispatch("fetchAllProfessionnels");
              })
              .catch((error) => {
                console.error("Erreur lors de la suppression:", error);
                this.$toast.error(
                  "Une erreur s'est produite lors de la suppression du professionnel",
                  { duration: 5000 }
                );
              });
          } else {
            this.$toast.error("Suppression annulée", { duration: 5000 });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("fetchAllProfessionnels");
  },
};
</script>

<style scoped>
.tableauProf {
  margin: auto;
}
.tableauProf td,
.tableauProf th {
  border: 1px solid #ddd;
  padding: 8px;
}
.buttonNouveauContainer {
  display: block;
}
</style>
