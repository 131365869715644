<template>
  <div>
    <vue-scheduler
      :events="events"
      :event-display="eventDisplay"
      :labels="{
        today: 'Aujourd\'hui',
        back: 'Précédent',
        next: 'Suivant',
        month: 'Mois',
        week: 'Semaine',
        day: 'jour',
        all_day: 'heure',
      }"
      :overrideCheckIfCanClickDay="getSiDateEstDispo"
      :available-views="['month']"
      @event-clicked="eventClicked"
      @day-clicked="dayClicked"
      @month-changed="monthChanged"
      :disableDialog="true"
    />
    <b-modal id="modal-rv" centered hide-footer title="Rendez-vous">
      <ReservationRv
        v-bind:dateChoisi="dateChoisi"
        v-bind:dispos="disposChoisi"
        v-bind:professionnel="professionnel"
      />
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/components/v-calendar-scheduler/components/EventBus";
import moment from "moment";
import ReservationRv from "@/components/ReservationRv";
export default {
  name: "RendezVousPopup",
  props: ["professionnel"],
  components: {
    ReservationRv,
  },
  data() {
    return {
      dateChoisi: null,
      disposChoisi: null,
      events: [],
      monthCached: [],
      disposParMois: [],
    };
  },
  methods: {
    eventDisplay() {
      return "Disponible";
    },
    eventClicked(event) {
      this.eventChoisi = event;
      EventBus.$emit("day-clicked", event.date);
    },
    dayClicked(event) {
      var datetime = moment(event);
      if (this.getSiDateEstDispo(datetime)) {
        this.dateChoisi = moment(datetime).format("YYYY-MM-DD");
        var mois = moment(datetime).format("YYYY-MM");
        this.disposChoisi = this.getDisposDuJour(mois, this.dateChoisi);
        this.$bvModal.show("modal-rv");
        // this.$store.dispatch('getDisposProfessionnel', this.getProfessionnelInfoBodyJour(datetime)).then(response => {
        //   this.dateChoisi = datetime.format('YYYY-MM-DD')
        //   this.disposChoisi = response['data']
        //   this.$bvModal.show('modal-rv')
        // })
      }
    },
    getDisposDuJour(mois, jour) {
      var disposList = [];
      this.disposParMois.forEach((dispoByMonth) => {
        if (dispoByMonth.mois === mois) {
          dispoByMonth.dispos.forEach((dispoItem) => {
            if (dispoItem["date"] === jour) {
              disposList.push(dispoItem);
            }
          });
        }
      });
      return disposList;
    },
    getProfessionnelInfoBodyJour(datetime) {
      var startDatetime = moment(datetime)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      var endDatetime = moment(datetime)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      return {
        datetime_start: startDatetime,
        datetime_end: endDatetime,
        professionnel_id: this.professionnel.id,
        siFusionne: true,
      };
    },
    getProfessionnelInfoBodyMois(mois) {
      var startDatetime = moment(mois, "YYYY-MM")
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      var endDatetime = moment(mois, "YYYY-MM")
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      return {
        datetime_start: startDatetime,
        datetime_end: endDatetime,
        professionnel_id: this.professionnel.id,
        siFusionne: true,
      };
    },
    getSiDateEstDispo(date) {
      var dateString = date.format("YYYY-MM-DD");
      return (
        this.events.filter((dispo) => dispo.date === dateString).length !== 0
      );
    },
    monthChanged(event) {
      this.ajouterMois(moment(event).format("YYYY-MM"));
    },
    ajouterMois(mois) {
      if (!this.monthCached.includes(mois)) {
        this.$store
          .dispatch(
            "getDisposProfessionnel",
            this.getProfessionnelInfoBodyMois(mois)
          )
          .then((response) => {
            var jours = [];
            var now = moment().add(20, "minutes");
            this.disposParMois.push({ mois: mois, dispos: response["data"] });
            response["data"].forEach((dispos) => {
              var momentDispo = moment(
                dispos["date"] + dispos["endTime"],
                "YYYY-MM-DD HH:mm:ss"
              );
              if (!jours.includes(dispos["date"]) && momentDispo >= now) {
                this.events.push({ date: dispos["date"] });
                jours.push(dispos["date"]);
              }
            });
          });
        this.monthCached.push(mois);
      }
    },
  },
  created() {
    this.monthCached = [];
    this.ajouterMois(moment().format("YYYY-MM"));
  },
};
</script>

<style>
.v-cal-dialog {
  z-index: 2000 !important;
}
.v-cal-header__actions .v-cal-button:last-child {
  color: white !important;
}
.v-cal-header__actions:hover .v-cal-button:last-child:hover {
  background-color: #29689c !important;
}
.v-cal-button {
  color: white !important;
  border-radius: 5px !important;
  margin: 5px;
}
.v-cal-button:hover {
  background-color: #29689c !important;
}
.modal-header:hover .close:hover {
  background-color: #29689c !important;
}
</style>
