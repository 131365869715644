import axios from "axios";

const state = {};

const getters = {};

const actions = {
  getAllOrdres(context) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/ordres",
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getOrdre(context, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/ordres/" + id,
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOrdre(context, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/ordres/" + id,
        method: "delete",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  registerOrUpdateOrdre({ commit }, { ordre, logo }) {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      let x;
      for (x in ordre) {
        bodyFormData.append(x, ordre[x]);
      }
      if (logo) {
        bodyFormData.append("logo", logo);
      }
      const isNew = ordre.id === undefined;
      const url = "/" + (isNew ? "ordres" : "ordres/" + ordre.id);
      const method = isNew ? "post" : "put";
      axios({
        url: url,
        method: method,
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
