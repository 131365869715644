<template>
  <form class="form is_content" @submit.prevent="handleSubmit">
    <div v-for="field in fields" :key="field.name" class="input is_wrap">
      <label :for="field.name">{{ field.label }}</label>
      <input
        v-if="field.type !== 'textarea' && field.type !== 'select'"
        :type="field.type"
        :name="field.name"
        :id="field.name"
        v-model="formData[field.name]"
        class="form-control"
      />
      <textarea
        v-if="field.type === 'textarea'"
        :name="field.name"
        :id="field.name"
        v-model="formData[field.name]"
        class="form-control"
      ></textarea>
      <select
        v-if="field.type === 'select'"
        :name="field.name"
        :id="field.name"
        v-model="formData[field.name]"
        class="form-control"
      >
        <option
          v-for="option in field.options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary">
      {{ submitButtonText }}
    </button>
  </form>
</template>

<script>
export default {
  name: "ElementForm",
  props: {
    fields: Array,
    initialData: Object,
    submitButtonText: String,
  },
  data() {
    return {
      formData: this.initialData || {},
    };
  },
  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        console.log("initialData watcher triggered with:", newVal);
        this.formData = { ...newVal };
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("form-submit", this.formData);
    },
    setFormData(data) {
      console.log("setFormData called with:", data);
      this.formData = { ...data };
    },
  },
};
</script>
