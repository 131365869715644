<template>
  <div id="containeur">
    <form v-on:submit.prevent="send">
      <div class="eltcontain"><h1>INSCRIPTION</h1></div>
      <div class="eltcontain">
        <div class="error">{{ error_global }}</div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.first_name.$touch()"
              name="first_name"
              type="text"
              v-model="first_name"
              placeholder="Prénom"
            ></b-form-input>
          </div>
          <div
            class="error"
            v-if="!$v.first_name.required && $v.first_name.$error"
          >
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.last_name.$touch()"
              name="last_name"
              type="text"
              v-model="last_name"
              placeholder="Nom"
            ></b-form-input>
          </div>
          <div
            class="error"
            v-if="!$v.last_name.required && $v.last_name.$error"
          >
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.address.$touch()"
              name="address"
              type="text"
              v-model="address"
              placeholder="Adresse"
            ></b-form-input>
          </div>
          <div class="error" v-if="!$v.address.required && $v.address.$error">
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.city.$touch()"
              name="city"
              type="text"
              v-model="city"
              placeholder="Ville"
            ></b-form-input>
          </div>
          <div class="error" v-if="!$v.city.required && $v.city.$error">
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.province.$touch()"
              name="province"
              type="text"
              v-model="province"
              placeholder="Province"
            ></b-form-input>
          </div>
          <div class="error" v-if="!$v.province.required && $v.province.$error">
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.zip_code.$touch()"
              name="zip_code"
              type="text"
              v-model="zip_code"
              placeholder="Code postal"
            ></b-form-input>
          </div>
          <div class="error" v-if="!$v.zip_code.required && $v.zip_code.$error">
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.phone.$touch()"
              name="phone"
              type="number"
              v-model="phone"
              placeholder="Téléphone"
            ></b-form-input>
          </div>
          <div v-if="$v.phone.$anyDirty">
            <div class="error" v-if="!$v.phone.required">*Champ requis</div>
            <div class="error" v-if="!$v.phone.minLength">
              Longueur minimale de 10 caractères
            </div>
            <div class="error" v-if="!$v.phone.numeric">
              Tous les caractères doivent être numériques
            </div>
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.email.$touch()"
              name="email"
              type="email"
              v-model="email"
              placeholder="Courriel"
            ></b-form-input>
          </div>
          <div v-if="$v.email.$anyDirty">
            <div class="error" v-if="!$v.email.required">*Champ requis</div>
            <div class="error" v-if="!$v.email.email">Courriel invalide</div>
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.password.$touch()"
              name="mdp"
              type="password"
              v-model="password"
              placeholder="Mot de passe"
            ></b-form-input>
          </div>
          <div class="error" v-if="!$v.password.required && $v.password.$error">
            *Champ requis
          </div>
        </div>
        <div class="ligne">
          <div>
            <b-form-input
              @blur="$v.c_password.$touch()"
              name="c-mdp"
              type="password"
              v-model="c_password"
              placeholder="Confirmation du mot de passe"
            ></b-form-input>
          </div>
          <div class="error" v-if="!$v.c_password.sameAsPassword">
            Le mot de passe doit être identique
          </div>
        </div>
      </div>
      <div>
        J'ai lu et j'accepte les
        <a :href="lienForum + '/termes-et-conditions/'">termes et conditions</a
        >.<input
          type="checkbox"
          v-model="accepteLesConditions"
          style="margin-left: 10px"
        />
      </div>
      <div class="eltcontain">
        <div>
          <button
            class="btn btn-secondary"
            type="submit"
            :disabled="$v.$invalid || !accepteLesConditions"
          >
            S'INSCRIRE
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  numeric,
  email,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  name: "RegisterClientForm",
  props: ["recaptcha"],
  data() {
    return {
      error_global: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      city: "",
      address: "",
      province: "",
      zip_code: "",
      password: "",
      c_password: "",
      accepteLesConditions: false,
    };
  },
  computed: {
    lienForum() {
      return process.env.VUE_APP_FORUM_LIEN;
    },
  },
  methods: {
    send() {
      this.recaptcha.execute("login").then((token) => {
        this.error_global = "";
        const formData = new FormData();
        formData.append("token", token);
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        formData.append("city", this.city);
        formData.append("address", this.address);
        formData.append("province", this.province);
        formData.append("country", "Canada");
        formData.append("zip_code", this.zip_code);
        formData.append("password", this.password);
        formData.append("c_password", this.c_password);
        // Need to convert FormData in JSON format
        var object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        var json = object;
        this.$store
          .dispatch("register", json)
          .then(() => {
            this.$root.$bvToast.toast(
              "Vous êtes maintenant inscrit(e)! Veuillez vous connecter.",
              {
                title: "Félicitations!",
                variant: "success",
                solid: true,
              }
            );
            this.clearFields();
          })
          .catch((error) => {
            var erreurs = error.response.data;
            for (var er in erreurs) {
              let message = erreurs[er];
              if (erreurs[er] === "The email has already been taken.") {
                message =
                  "Le courriel est déjà utilisé par un autre utilisateur. Veuillez en choisir un autre.";
              }
              /* this.$bvToast.toast(message, {
                title: 'Erreur',
                variant: 'danger',
                solid: true
              }) */
              this.error_global = message;
            }
          });
      });
    },
    clearFields() {
      this.first_name = "";
      this.last_name = "";
      this.phone = "";
      this.email = "";
      this.city = "";
      this.address = "";
      this.country = "";
      this.province = "";
      this.zip_code = "";
      this.password = "";
      this.c_password = "";
      this.$v.$reset();
    },
  },
  validations: {
    first_name: {
      required,
    },
    last_name: {
      required,
    },
    phone: {
      required,
      minLength: minLength(10),
      numeric,
    },
    email: {
      required,
      email,
    },
    city: {
      required,
    },
    address: {
      required,
    },
    province: {
      required,
    },
    zip_code: {
      required,
    },
    password: {
      required,
    },
    c_password: {
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
}

#containeur {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  min-height: 60vh;
}
#containeur:hover {
  box-shadow: 6px 6px 6px 6px rgb(75, 73, 73);
}
button {
  margin-bottom: 20px;
  background-color: #ffc90e !important;
  color: #fff;
  font-family: roboto;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  border-color: #ffc90e;
}
.eltcontain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ligne {
  padding: 10px;
  flex: 50%;
  margin-bottom: 10px;
}

@media screen and (max-width: 1300px) {
  .eltcontain h1 {
    font-size: 5vw;
  }
  .eltcontain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: center;
  }
  input {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .ligne {
    width: 100%;
  }
}
</style>
