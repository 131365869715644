<template>
  <AdminPageWrapper>
    <div class="container" v-if="isReady">
      <b-form v-on:submit.prevent="soumettre" class="form-container">
        <template v-for="field in fields">
          <b-form-group
            :key="field.id"
            :label="field.label"
            :label-for="field.id"
            class="inputGroup"
          >
            <b-form-select
              v-if="field.type === 'select'"
              :id="field.id"
              v-model="item[field.id]"
            >
              <b-form-select-option
                v-for="option in field.options()"
                v-bind:key="option.id"
                v-bind:value="option.id"
              >
                {{ option.name }}
              </b-form-select-option>
            </b-form-select>

            <b-form-textarea
              v-else-if="field.type === 'textarea'"
              type="text"
              :id="field.id"
              :placeholder="field.label"
              v-model="item[field.id]"
            />

            <b-form-input
              v-else
              :required="field.required"
              :id="field.id"
              :placeholder="field.label"
              v-model="item[field.id]"
            />
          </b-form-group>
        </template>

        <div class="btnContainer">
          <b-button class="buttonSoumettre" type="submit">Soumettre</b-button>
          <b-button class="buttonBack" @click="back()">Annuler</b-button>
        </div>

        <span
          style="color: red"
          v-for="erreur in listeErreurs"
          v-bind:key="erreur"
        >
          {{ erreur }}
          <br />
        </span>
      </b-form>

      <b-modal
        id="modal-chargement"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        centered
        hide-footer
        title="Chargement"
      >
        Chargement...
      </b-modal>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "@/components/AdminPageWrapper.vue";

export default {
  name: "PageGestionForfait",
  components: {
    AdminPageWrapper,
  },
  data() {
    return {
      item: {},
      fields: [
        {
          id: "name",
          label: "Nom",
          type: "text",
          required: true,
        },
        {
          id: "description",
          label: "Description",
          type: "textarea",
          required: true,
        },
        {
          id: "nbr_consultations",
          label: "Nombre de consultations",
          type: "number",
          required: true,
        },
        {
          id: "price",
          label: "Prix ($)",
          type: "number",
          required: true,
        },
      ],
      listeErreurs: [],
      retourServeur: "",
      isReady: false,
    };
  },
  computed: {
    siNouveau() {
      return this.$route.params.id == null;
    },
  },
  methods: {
    soumettre() {
      this.listeErreurs = [];
      if (!this.item.name) {
        this.listeErreurs.push("Le nom du forfait est requis");
      }

      if (!this.item.description) {
        this.listeErreurs.push("La description du forfait est requise");
      }

      if (!this.item.nbr_consultations) {
        this.listeErreurs.push(
          "Le nombre de bloques de consultations du forfait est requis"
        );
      }

      if (!this.item.price) {
        this.listeErreurs.push("Le prix du forfait est requis");
      }

      if (this.listeErreurs.length === 0) {
        this.$bvModal.show("modal-chargement");
        console.log(this.item);
        this.$store
          .dispatch("forfaits/registerOrUpdateForfait", this.item)
          .then((response) => {
            if (response.status === 200) {
              if (this.$route.params.id) {
                this.$toast.success("Le forfait a été modifié avec succès", {
                  duration: 5000,
                });
              } else {
                history.pushState({}, document.title, window.location.href);
                this.$toast.success("Le forfait a été ajouté avec succès", {
                  duration: 5000,
                });
              }
              this.$router.push({ name: "ListeForfaits" });
              this.$bvModal.hide("modal-chargement");
            }
          })
          .catch((err) => {
            const errors = err.response.data;
            console.error(errors);
            this.$bvModal.hide("modal-chargement");
          });
      }
    },
    back() {
      this.$router.push({ path: "/admin/liste-forfaits" });
    },
  },
  created() {
    if (!this.siNouveau) {
      this.$store
        .dispatch("forfaits/getForfait", this.$route.params.id)
        .then((item) => {
          this.item = item;
          this.isReady = true;
        });
    } else {
      this.item = {};
      this.isReady = true;
    }
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.inputGroup {
  width: 100%;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>
