<template>
  <div class="container">
    <form v-on:submit.prevent="login">
      <div v-if="errors.length">
        <p class="errorMessage" v-for="error in errors" :key="error">
          {{ error }}
        </p>
      </div>
      <div class="eltcontain"><h1>CONNEXION</h1></div>
      <div class="eltcontain">
        <div>
          <b-form-input
            @blur="$v.email.$touch()"
            required
            type="email"
            name="email"
            placeholder="Courriel"
            v-model="email"
          />
          <div
            class="errorMessage"
            v-if="!$v.email.required && $v.email.$error"
          >
            *Champ requis
          </div>
        </div>
        <div>
          <b-form-input
            @blur="$v.password.$touch()"
            required
            type="password"
            name="password"
            placeholder="Mot de passe"
            v-model="password"
          />
          <div
            class="errorMessage"
            v-if="!$v.password.required && $v.password.$error"
          >
            *Champ requis
          </div>
        </div>
      </div>
      <a href="/mot-de-passe">Mot de passe oublié?</a>
      <div class="eltcontain">
        <div class="submitContainer">
          <b-button type="submit">CONNEXION</b-button>
        </div>
      </div>
    </form>
    <b-modal
      id="modal-connexion"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Connexion"
    >
      Connexion...
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "ConnectionWindow",
  props: ["recaptcha"],
  data() {
    return {
      errors: [],
      email: "",
      password: "",
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    login() {
      this.$bvModal.show("modal-connexion");
      this.recaptcha.execute("login").then((token) => {
        this.errors = [];
        this.$store
          .dispatch("login", {
            email: this.email,
            password: this.password,
            token: token,
          })
          .then(() => {
            this.$store
              .dispatch("requestUserDetails") // get user details
              .then((res) => {
                this.$bvModal.hide("modal-connexion");
                location.reload();
              });
          })
          .catch((error) => {
            this.$bvModal.hide("modal-connexion");
            if (error.is2Fa) {
              this.$router.push({ name: "MultiFactor" });
            } else {
              this.errors.push(error.message);
            }
          });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eltcontain {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}
.submitContainer {
  vertical-align: bottom;
}
.errorMessage {
  font-size: 14px;
  color: red;
}
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  min-height: 60vh;
}
.container:hover {
  box-shadow: 6px 6px 6px 6px rgb(75, 73, 73);
}

h1 {
  margin-right: auto;
  margin-left: auto;
}
input {
  margin-bottom: 10px;
}
button {
  margin-bottom: 20px;
  background-color: #ffc90e !important;
  color: #fff;
  font-family: roboto;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  border-color: #ffc90e;
}
button:hover {
  background-color: #235c8f !important;
}

a {
  color: #29689c;
}
@media screen and (max-width: 1300px) {
  .textbox-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-size: 5vw;
  }
}
</style>
