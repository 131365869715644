<template>
  <div>
    <div v-if="!eventChoisiType.includes('vacances')">
      Date: {{ date }}<br />
      Heure début: {{ eventChoisi.startTime }}<br />
      Heure fin: {{ eventChoisi.endTime }}<br />
    </div>
    <div v-else>
      Date début: {{ eventChoisi.datetime_start }}<br />
      Date fin: {{ eventChoisi.datetime_end }}<br />
    </div>
    <div v-if="eventChoisiType == 'rvAvecProfessionnel'">
      Professionnel: {{ eventChoisi.professionnel }}
    </div>
    <div v-if="eventChoisiType == 'rvAvecClient'">
      Client: {{ eventChoisi.client }}
    </div>
    <div v-if="eventChoisi.clientEmail">
      Email Client: {{ eventChoisi.clientEmail }}
    </div>
    <div v-if="eventChoisi.webLink">
      <a :href="eventChoisi.webLink">Lien rencontre</a>
    </div>
    <div v-if="eventChoisi.meetingNumber">
      Numéro de rencontre: {{ eventChoisi.meetingNumber }}
    </div>
    <div v-if="eventChoisi.password">
      Mot de passe (web): {{ eventChoisi.password }}
    </div>
    <div v-if="eventChoisi.telephoneQuebec">
      Numéro téléphone (Québec): {{ eventChoisi.telephoneQuebec }}
    </div>
    <div v-if="eventChoisi.telephoneMontreal">
      Numéro téléphone (Montréal): {{ eventChoisi.telephoneMontreal }}
    </div>
    <div v-if="eventChoisi.telephoneAccessCode">
      Mot de passe (téléphone): {{ eventChoisi.telephoneAccessCode }}
    </div>
    <div v-if="eventChoisi.commentaires">
      Commentaires: {{ eventChoisi.commentaires }}
    </div>
    <br v-if="eventChoisiType == 'rvAvecProfessionnel'" />
    <b-button
      class="btnPremierRv"
      v-if="eventChoisiType == 'rvAvecProfessionnel'"
      :href="lienFicheSante"
      >C'est votre premier rendez-vous avec ce professionnel?</b-button
    >
    <br v-if="eventChoisiType == 'rvAvecProfessionnel'" />
    <br />
    <b-button class="btnFermer" @click="fermer">fermer</b-button>
    <b-button
      v-if="eventChoisiType.includes('vacances')"
      @click="supprimerVacance"
      >Supprimer vacance</b-button
    >
    <b-button
      v-if="this.eventChoisi.typeDispo == 'unique'"
      @click="supprimerDispoUnique()"
      >Supprimer dispo unique</b-button
    >
    <b-button
      v-if="this.eventChoisi.typeDispo == 'hebdo'"
      @click="supprimerDispoHebdo()"
      >Supprimer dispo hebdomadaire</b-button
    >
    <b-button
      v-if="
        (eventChoisiType == 'rvAvecClient' ||
          eventChoisiType == 'rvAvecProfessionnel') &&
        !rvDansPassee
      "
      class="btnRendezVous"
      @click="supprimerRendezVous()"
      >Supprimer le rendez-vous</b-button
    >
    <b-modal
      id="modal-annulation"
      centered
      hide-footer
      title="Annulation 72h avant le rendez-vous."
    >
      Chez Pro Ressources.ca, vous pouvez annuler un rendez-vous 72h avant la
      consultation. Après ce délais, c'est à la discrétion du professionnel de
      juger la raison de votre annulation. Vous pouvez le contacter au courriel
      suivant :
      <a :href="'mailto:' + this.eventChoisi.professionnelEmail">{{
        this.eventChoisi.professionnelEmail
      }}</a>
      Pour en savoir plus, consultez notre
      <a :href="lienPolitiqueRemboursement"
        >politique de report et de remboursement</a
      >
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ReservationDetail",
  props: ["eventChoisi", "eventChoisiType"],
  data() {
    return {
      lienPolitiqueRemboursement: process.env.VUE_APP_REMBOURSEMENT_LIEN,
      lienFicheSante: process.env.VUE_APP_FORMULAIRE_LIEN,
    };
  },
  computed: {
    date() {
      return moment(this.eventChoisi.date).format("DD MMMM YYYY");
    },
    peutSupprimerLeRv() {
      var date = moment(this.eventChoisi.date).subtract(3, "days");
      return date > moment() || this.eventChoisiType === "rvAvecClient";
    },
    rvDansPassee() {
      var date = moment(this.eventChoisi.date);
      return date <= moment();
    },
    rvDepasse48h() {
      var date = moment(this.eventChoisi.date).add(2, "days");
      return date <= moment();
    },
  },
  methods: {
    fermer() {
      this.$bvModal.hide("modal-rvDetail");
    },
    supprimerRendezVous() {
      if (!this.peutSupprimerLeRv) {
        // this.$bvModal.msgBoxOk('Le rendez-vous est dans moins de 72h. Veuillez-contacter le professionnel à cet adresse courriel: <a>' + this.eventChoisi.professionnelEmail + '</a>', {
        //   size: 'sm',
        //   buttonSize: 'sm',
        //   headerClass: 'p-2 border-bottom-0',
        //   footerClass: 'p-2 border-top-0',
        //   centered: true
        // })
        this.$bvModal.show("modal-annulation");
      } else {
        this.$bvModal
          .msgBoxConfirm("Voulez-vous vraiment supprimer ce rendez-vous?", {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Oui",
            cancelTitle: "Non",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$bvModal.show("modal-chargement");
              this.$store
                .dispatch("deleteReservation", this.eventChoisi.id)
                .then(() => {
                  this.$toast.open(
                    "un courriel vous a été envoyé pour la confirmation de l'annulation du Rendez-vous.",
                    "success",
                    { duration: 5000 }
                  );
                  this.$bvModal.hide("modal-chargement");
                  this.$router.go();
                });
            }
          });
      }
    },
    supprimerVacance() {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer cet élément?", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$bvModal.show("modal-chargement");
            this.$store
              .dispatch("deleteVacance", this.eventChoisi.id)
              .then(() => {
                this.$bvModal.hide("modal-chargement");
                this.$router.go();
              });
          }
        });
    },
    supprimerDispoUnique() {
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet cette disponibilité unique?",
          {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Oui",
            cancelTitle: "Non",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$bvModal.show("modal-chargement");
            this.$store
              .dispatch("deleteDispoUnique", this.eventChoisi.id)
              .then(() => {
                this.$bvModal.hide("modal-chargement");
                this.$router.go();
              });
          }
        });
    },
    supprimerDispoHebdo() {
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet cette disponibilité hebdomadaire? Cela va supprimer toutes les disponibilités à ces heures là",
          {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Oui",
            cancelTitle: "Non",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$bvModal.show("modal-chargement");
            this.$store
              .dispatch("deleteDispoHebdo", this.eventChoisi.id)
              .then(() => {
                this.$bvModal.hide("modal-chargement");
                this.$router.go();
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.btnFermer {
  margin-right: 10px;
}
.btnPremierRv {
  font-family: "Open Sans" !important;
  background-color: #ffc90e !important;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
  color: white !important;
  font-weight: 300 !important;
  border-radius: 6px !important;
  transition: all 0.3s !important;
  border: none !important;
}
.btnPremierRv:hover {
  background-color: #29689c !important;
}
@media screen and (max-width: 1024px) {
  .btnRendezVous {
    margin-top: 10px;
  }
}
</style>
