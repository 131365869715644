import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from "vuelidate";
import VueScheduler from "./components/v-calendar-scheduler";
import "./components/v-calendar-scheduler/lib/main.css";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Maska from "maska";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faUserSecret,
  faLock,
  faKey,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { faVimeo } from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(faUserSecret, faLock, faKey, faCaretRight, faVimeo);

Vue.use(VueScheduler, {
  locale: "fr",
});
Vue.use(Vuelidate);
Vue.use(Vuex);
Vue.use(VueToast);
Vue.use(Maska);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  created() {
    const userInfo = sessionStorage.getItem("token");
    if (userInfo) {
      // const userData = JSON.parse(userInfo)
      // this.$store.commit('setUserData', userData)
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // this.$store.dispatch('logout')
        }
        return Promise.reject(error);
      }
    );
  },
  store,
  render: (h) => h(App),
}).$mount("#app");
