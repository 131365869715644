import axios from "axios";

const state = {};

const getters = {};

const actions = {
  getAllCategories(context) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/categories",
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCategory(context, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/categories/" + id,
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCategory(context, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/categories/" + id,
        method: "delete",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  registerOrUpdateCategory({ commit }, category) {
    return new Promise((resolve, reject) => {
      const isNew = category.id === undefined;
      const url = "/" + (isNew ? "categories" : "categories/" + category.id);
      const method = isNew ? "post" : "put";
      axios({
        url: url,
        method: method,
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: category,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
