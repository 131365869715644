<template>
  <AdminPageWrapper>
    <div class="content">
      <div class="content-top">
        <div class="content-top-top">
          <span>Recherche</span
          ><b-input
            class="top-searchbar"
            type="search"
            v-model="query"
            @input="updateTableData"
          />
        </div>
        <div class="content-top-bottom">
          <b-button :to="{ name: 'pageGestionForfait' }">
            Ajouter un forfait
          </b-button>
        </div>
      </div>
      <div class="content-middle">
        <b-table ref="forfaitsTable" :fields="fields" :items="myProvider">
          <template #cell(edit)="data">
            <b-button
              :to="{
                name: 'pageGestionForfait',
                params: { id: data.item.id },
              }"
            >
              Modifier
            </b-button>
          </template>
          <template #cell(delete)="data">
            <b-button variant="danger" @click="deleteItem(data.item.id)">
              Supprimer
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "@/components/AdminPageWrapper.vue";

export default {
  name: "ListeForfaits",
  components: {
    AdminPageWrapper,
  },
  data() {
    return {
      isForfaitsLoaded: false,
      forfaits: [],
      query: "",
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "nbr_consultations",
          label: "Nombre de consultations",
          sortable: true,
        },
        {
          key: "price",
          label: "Prix ($)",
          sortable: true,
        },
        {
          key: "edit",
          label: "Modifier",
        },
        {
          key: "delete",
          label: "Supprimer",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("forfaits/getAllForfaits")
        .then((forfaits) => {
          this.forfaits = forfaits;
          this.isForfaitsLoaded = true;
          this.updateTableData();
        })
        .catch(() => {
          console.error("Error fetching data:", error);
        });
    },
    updateTableData() {
      this.$refs.forfaitsTable.refresh();
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer le forfait?", {
          title: "Supprimer",
          cancelTitle: "Non",
          okTitle: "Oui",
        })
        .then((canDelete) => {
          if (canDelete) {
            this.$store
              .dispatch("forfaits/deleteForfait", id)
              .then((response) => {
                this.fetchData();
                this.$toast.success(response.message, { duration: 5000 });
              });
          } else {
            this.$toast.error("Suppression annulée", { duration: 5000 });
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response.data.message ||
              "Erreur lors de la suppression du forfait",
            { duration: 5000 }
          );
        });
    },
    myProvider(ctx, callback) {
      const whenForfaitsLoaded = () => {
        const query = this.query;
        const forfaitsWithQuery = this.forfaits.filter(
          (element) =>
            element.name.includes(query) ||
            element.description.includes(query) ||
            element.nbr_consultations.toString().includes(query) ||
            element.price.toString().includes(query) ||
            query === ""
        );
        callback(forfaitsWithQuery);
      };
      if (!this.isForfaitsLoaded) {
        this.fetchData().then(() => {
          whenForfaitsLoaded();
        });
      } else {
        whenForfaitsLoaded();
      }
      return null;
    },
  },
};
</script>

<style scoped>
.content-middle {
  display: flex;
  flex-direction: column;
  width: 99vw;
  align-items: flex-start;
  background-color: white;
}
.content-top-top {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.content-top-bottom {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.content-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.top-searchbar {
  min-width: 200px;
  width: 50%;
  max-width: 100%;
  margin-left: 10px;
}
</style>
