<template>
  <footer class="footer">
    <div id="conteneur">
      <div class="element-conteneur">
        <p><a :href="lienForum + '/contact/'">Contact</a></p>
      </div>
      <div class="element-conteneur">
        <p><a :href="lienForum + '/a-propos/'">À propos</a></p>
      </div>
      <div class="element-conteneur">
        <p><a :href="lienForum + '/services/'">Services</a></p>
      </div>
      <div class="element-conteneur">
        <p><a :href="lienForum + '/conditions/'">Conditions</a></p>
      </div>
      <div class="element-conteneur">
        <p><a :href="lienForum + '/autres/'">Autres</a></p>
      </div>
    </div>
    <p id="proressource">
      Ⓒ 2022-{{ currentYear }} - proressources.ca . Tous droits réservés.
    </p>
  </footer>
</template>

<script>
export default {
  computed: {
    lienForum() {
      return process.env.VUE_APP_FORUM_LIEN;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #29689c;
  padding-top: 2vh;
}
#proressource {
  color: white;
  padding-top: 1vw;
}
#conceptionwm {
  color: white;
  padding-bottom: 1vw;
}
#conteneur {
  display: flex;
  width: 80%;
  margin-right: auto;
  flex-direction: row;
  margin-left: auto;
  justify-content: center;
}
.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.element-conteneur {
  width: 20%;
}
.element-conteneur p,
.element-conteneur a {
  color: white;
  font-family: "Open Sans", Sans-serif;
  font-weight: 700;
  font-size: 20px;
}
.element-conteneur a:hover {
  color: #ffc90e;
  text-decoration: none;
}
#modal-connection {
  background-color: #00528c !important;
}

@media screen and (max-width: 900px) {
  .element-conteneur {
    width: 100%;
  }
  #conteneur {
    flex-direction: column;
  }
  .element-conteneur p {
    font-size: 20px;
  }
}
</style>
