<template>
  <div class="content is_wrap">
    <h1>Votre forfait</h1>

    <div v-if="isLoading" class="loading-container">
      <div class="loader"></div>
    </div>

    <div v-else-if="entrepriseData && forfait">
      <div class="content is_actions">
        <h2>{{ forfait?.name }}</h2>
        <button @click="openMailto">Changement de forfait</button>
      </div>

      <div class="content is_grid">
        <div class="card is_wrap">
          <div class="card is_title">
            <h3>Description</h3>
          </div>
          <div class="card is_content">
            <p>
              {{ forfait?.description }}
            </p>
          </div>
        </div>
        <div class="card is_wrap">
          <div class="card is_title">
            <h3>Nombre de consultations</h3>
          </div>
          <div class="card is_content">
            <p>
              {{ consultationsRestantes || forfait?.nbr_consultations }}
              /
              {{ forfait?.nbr_consultations }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card is_wrap">
      <div class="card is_content">
        <p>Aucun forfait n'est actuellement associé à votre profil.</p>
      </div>
      <div>
        <button @click="openMailto">Souscrire à un forfait</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "EntrepriseUsers",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("entreprises", ["entreprise"]),
    ...mapState("forfaits", ["forfait"]),
    ...mapState("consultations", ["consultationsRestantes"]),
    entrepriseData() {
      return this.entreprise || {};
    },
  },
  methods: {
    ...mapActions("entreprises", ["getEntreprise"]),
    ...mapActions("forfaits", ["getForfait"]),
    ...mapActions("consultations", ["getConsultationsRestantes"]),
    async loadForfaitData() {
      if (this.entrepriseData.forfait_id) {
        await this.getForfait(this.entrepriseData.forfait_id);
        await this.getConsultationsRestantes(this.entrepriseData.id);
      }
    },
    openMailto() {
      const entrepriseName = this.entrepriseData?.name || "";
      const subject = this.entrepriseData?.forfait_id
        ? `Changement de forfait pour ${entrepriseName}`
        : `Souscription à un forfait pour ${entrepriseName}`;

      const mailtoUrl = `mailto:contact@proressources.ca?subject=${encodeURIComponent(
        subject
      )}`;
      window.location.href = mailtoUrl;
    },
  },
  created() {
    this.isLoading = true;
    const entrepriseId = this.$route.params.id;
    this.getEntreprise(entrepriseId)
      .then(() => {
        if (this.entrepriseData.forfait_id) {
          this.loadForfaitData();
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>
/* CONTENT */
.content.is_wrap {
  background-color: white;
  border-radius: 10px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 4rem auto;
}

.content.is_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
  border-top: 1px solid gray;
}

.content.is_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

button {
  background-color: #ffc107;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

/* CARD */
.card.is_wrap {
  background-color: #f6f6f6;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* LOADING */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #f6f6f6;
  border-bottom-color: #ffc107;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
